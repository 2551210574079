<template>
  <layoutContainer>
    <div class="enter-container" :style="{height: `${containerHeight}px`}">
      <div class="head">实名认证</div>
      <div class="container">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名" label-width="120px" size="medium" label-position="right" prop="pass">
            <el-input type="password" v-model="ruleForm.name" autocomplete="off" class="change-password-input"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" label-width="120px" size="medium" label-position="right" prop="checkPass">
            <el-input type="password" v-model="ruleForm.nameCode" autocomplete="off" class="change-password-input"></el-input>
          </el-form-item>
          <div class="reset-password-btn">开始认证</div>
          <div class="reset-password-btn-already">已认证</div>
        </el-form>
      </div>
      <div class="notice">
        <img src="/img/userinfo-icon/notice.png" class="notice-icon">
        <div>
          <div class="notice-content">·  认证后将不能再修改，如需修改请<span style="color: #5AD3CD; text-decoration: underline;" @click="contact">联系客服</span>。</div>
          <div class="notice-content">·  因认证时需要使用摄像头，建议使用chrome、safari等浏览器。</div>
        </div>
        
      </div>
    </div>
  </layoutContainer>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight;
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight;
      const footerHeight = document.getElementsByClassName("footer-container")[0].offsetHeight;
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70
    })
  },
  data() {
    return {
      containerHeight: 0,
      ruleForm: {
        name: '',
        nameCode: '',
      },
    }
  },
  methods: {
    contact() {
    }
  }
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  background: #ffffff;
  padding: 30px 0;
  border-radius: 12px;
  overflow: scroll;
  .head {
    width: 165px;
    height: 62px;
    background: #5AD3CD;
    border-radius: 0 30px 30px 0;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 62px;
    text-align: center;
  }
  .container {
    width: 500px;
    padding-top: 30px;
    margin: 0 auto;
  }
  .change-password-input {
    width: 300px;
  }
  .pr {
    position: relative;
  }
  .code-btn {
    width: 100px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: -30px;
    z-index: 222;
    color: #ef8466;
    font-size: 14px;
    border: none;
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;
  }
  .reset-password-btn {
    width: 304px;
    height: 74px;
    background: #5AD3CD;
    border-radius: 40px 40px 40px 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 74px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 0 auto;
    cursor: pointer;
  }

  .reset-password-btn-already {
    width: 304px;
    height: 74px;
    background: #fff;
    border: 1px solid #5AD3CD;
    border-radius: 40px 40px 40px 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #5AD3CD;
    line-height: 74px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 20px auto;
    cursor: pointer;
  }

  .notice {
    width: 800px;
    padding: 20px;
    background-color: #f6f6f8;
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    margin-top: 20px;
    margin: 30px auto;
    .notice-icon {
      width: 48px;
      height: 48px;
    }
    .notice-content {
      flex: 1;
      margin-left: 30px;
      margin-top: 12px;
      line-height: 24px;
      font-size: 24px;
    }
  }
  
}
</style>
